import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { formatTemplateData } from '@utils/templates'
import Container from '@layout/Container'
import ComponentContainer from '@layout/ComponentContainer'
import FlexComponents from '@layout/FlexComponents'
import SeriesBackgroundText from '@components/Series/SeriesBackgroundText'
import SeriesBeerList from '@components/Series/SeriesBeerList'
import SeriesBadge from '@components/Series/SeriesBadge'
import TextWithInlineChildren from '@components/Text/TextWithInlineChildren'
import { useSeries } from '@hooks/useSeries'
import Image from '@elements/Image'
import { camelCase } from '@utils/strings'
import { useTallestInRow } from '@hooks/useTallestInRow'

const SeriesTemplate = ({ data }) => {
  const {
    wpPage: {
      title,
      templateSeries: {
        intro: { textContent },
        flexibleContent,
        ...templateData
      },
      uri,
      seo,
      ...pageData
    },
  } = data
  seo.path = uri
  // BUG IN SOURCE DATA. TAXONOMY NOT PULLING DATA
  const series = useSeries('young-lions')
  const { name: seriesName } = series
  const template = {
    activeSlug: series.slug,
    activeNavBarSlug: 'beers',
    heroType: '',
    designations: ['isSeriesPage'],
    theme: 'dark',
    seo,
  }

  const icon = series.contentSeries?.icon

  let beers = series.beers.nodes.sort(function (a, b) {
    return a.menuOrder - b.menuOrder
  })

  beers = useTallestInRow(beers)

  return (
    <Container template={formatTemplateData(template)}>
      <ComponentContainer size="wide" collapse>
        <SeriesBackgroundText
          name={seriesName}
          breakWords={false}
          isSticky={false}
        />
        <SeriesBeerList
          noSidebar
          beers={beers}
          style={{ justifyContent: 'center' }}
        />
      </ComponentContainer>
      <ComponentContainer size="wide">
        <TextWithInlineChildren
          isFlex
          textContent={textContent}
          textSize="large"
          contentAlign="center"
          size="narrow"
          noGrid
        >
          {icon && (
            <SeriesBadge large>
              <Image source={icon} alt_text={`${seriesName} icon`} />
            </SeriesBadge>
          )}
        </TextWithInlineChildren>
      </ComponentContainer>
      {flexibleContent &&
        flexibleContent.map(({ __typename, ...componentProps }, index) => {
          const layout = camelCase(
            __typename.split('FlexibleContent_').slice(-1)[0]
          )
          return (
            <FlexComponents
              key={`${layout}-${index}`}
              layout={layout}
              componentProps={componentProps}
            />
          )
        })}
    </Container>
  )
}

export default SeriesTemplate

export const pageQuery = graphql`
  query SeriesTemplate($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      uri
      templateSeries {
        intro {
          textContent {
            selectedFields
            heading
            content
            link {
              data {
                target
                title
                url
              }
              type
            }
          }
        }
        flexibleContent {
          ... on WpPage_Templateseries_FlexibleContent_ColumnsBlock {
            __typename
            columns {
              url
              selectedfields
              heading
              fieldGroupName
              content
            }
            textContent {
              content
              fieldGroupName
              link {
                data {
                  target
                  title
                  url
                }
                fieldGroupName
                text
                type
              }
              heading
              selectedFields
            }
          }
          ... on WpPage_Templateseries_FlexibleContent_FormBlock {
            __typename
            formId
            textContent {
              selectedFields
              heading
              content
              link {
                data {
                  target
                  title
                  url
                }
                fieldGroupName
                file {
                  id
                  sourceUrl
                }
                text
                type
              }
            }
          }
          ... on WpPage_Templateseries_FlexibleContent_Heading {
            __typename
            heading
            hasMedia
            media {
              videoUrl
              mediaType
              ratio
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1280, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
          ... on WpPage_Templateseries_FlexibleContent_ListBlock {
            containerWidth
            textContent {
              selectedFields
              heading
              content
            }
            list {
              label
              content
              link {
                title
                url
                target
              }
            }
          }
          ... on WpPage_Templateseries_FlexibleContent_ListBlock {
            containerWidth
            textContent {
              selectedFields
              heading
              content
            }
            list {
              label
              content
              link {
                title
                url
                target
              }
            }
          }
          ... on WpPage_Templateseries_FlexibleContent_Iframe {
            __typename
            embed
            containerWidth
          }
          ... on WpPage_Templateseries_FlexibleContent_Gallery {
            caption
            containerWidth
            featuredStyle
            imageAlign
            gallery {
              localFile {
                publicURL
                childImageSharp {
                  # TODO: Alter sizing
                  fluid(maxWidth: 1920, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Templateseries_FlexibleContent_MediaGrid {
            __typename
            mediaItems {
              media {
                videoUrl
                mediaType
                ratio
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                  mediaDetails {
                    width
                    height
                  }
                }
              }
              caption
            }
            containerWidth
          }
          ... on WpPage_Templateseries_FlexibleContent_Quote {
            content
            author
            authorMeta
          }
          ... on WpPage_Templateseries_FlexibleContent_RelatedPosts {
            __typename
            postType
            hasFeatured
            containerWidth
            posts {
              ...TeaserPostFields
            }
            pages {
              heading
              backgroundColor
              imageType
              link {
                target
                title
                url
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Templateseries_FlexibleContent_Slider {
            __typename
            sliderImages {
              id
              caption
              altText
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90) {
                    aspectRatio
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Templateseries_FlexibleContent_Spacer {
            __typename
          }
          ... on WpPage_Templateseries_FlexibleContent_Text {
            __typename
            containerWidth
            contentAlign
            typeSize
            textContent {
              content
              fieldGroupName
              heading
              link {
                data {
                  target
                  title
                  url
                }
                fieldGroupName
                file {
                  id
                  sourceUrl
                }
                text
                type
              }
              selectedFields
            }
          }
          ... on WpPage_Templateseries_FlexibleContent_TextWithRelatedPost {
            postType
            textContent {
              selectedFields
              heading
              content
            }
            posts {
              ...TeaserPostFields
            }
            pages {
              heading
              backgroundColor
              imageType
              link {
                target
                title
                url
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Templateseries_FlexibleContent_TextBanner {
            bannerText
            images {
              caption
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }

      seo {
        title
        twitterTitle
        twitterDescription
        twitterImage {
          description
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphTitle
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphDescription
        metaRobotsNoindex
        metaRobotsNofollow
        metaDesc
      }
    }
  }
`
