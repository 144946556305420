import { useStaticQuery, graphql } from 'gatsby'
// No variables permitted in static Queries ... YET!

const getAllSeries = () => {
  const {
    allWpSeries: { nodes: allSeries },
  } = useStaticQuery(
    graphql`
      query AllSeriesQuery {
        allWpSeries {
          nodes {
            id
            slug
            name
            beers {
              nodes {
                id
                title
                uri
                slug
                menuOrder
                contentBeer {
                  featureImageType

                  smallBottleImage {
                    altText
                    localFile {
                      extension
                      publicURL
                      childImageSharp {
                        fluid(maxWidth: 400, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                      }
                    }
                  }
                  largeBottleImage {
                    altText
                    localFile {
                      extension
                      publicURL
                      childImageSharp {
                        fluid(maxWidth: 400, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp_tracedSVG
                        }
                      }
                    }
                  }
                  smallCanImage {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 560, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  largeCanImage {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 560, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
            contentSeries {
              textContent {
                selectedFields
                content
                heading
                link {
                  data {
                    target
                    title
                    url
                  }
                  file {
                    id
                    sourceUrl
                  }
                  text
                  type
                }
              }
              icon {
                localFile {
                  extension
                  publicURL
                }
              }
            }
          }
        }
      }
    `
  )

  return allSeries
}

export const useSeries = (slug) => {
  const query = getAllSeries()
  return slug ? query.find((series) => series.slug === slug) : query
}
