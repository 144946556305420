export const buildAnchor = (string) => {
  return encodeURIComponent(string.toLowerCase()).replace(/%[0-9A-F]{2}/gi, '')
}

export const affixString = (string, { prefix, suffix } = {}) => {
  return (prefix || '') + string + (suffix || '')
}

export const concatRange = (min, max) => {
  return `${min}–${max}`
}

export const camelCase = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase()
    })
    .replace(/\s+/g, '')
}

export const capitalize = (s) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}
